<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Konzept</h1>
      <p>
        Unser Projekt <em>Granja Caimito</em> zielt darauf ab, auf nachhaltige Weise Lebensmittel für Menschen zu
        erzeugen, die ein natürliches Ökosystem nachahmen. Manche Leute nennen diesen Ansatz <em>regenerative
          Landwirtschaft</em>.
      </p>
      <p>
        Es geht uns nicht darum, aus dem Ökosystem nur zu entnehmen, sondern wir wollen Überfluss erzeugen
        so dass wir den Überschuss für den menschlichen Verzehr nutzen können, ohne das gesamte System in den
        Niedergang zu treiben.
      </p>

      <h2 class="underdashed text-xl">Der Bauernhof als Ökosystem</h2>
      <p>
        Unser Land ist 45 ha groß und liegt im Valle de los Pedroches in Andalusien, Spanien. Es befindet sich in einem
        alten agroforstwirtschaftlichen System, auf Spanisch <em>Dehesa</em> genannt. Die Landschaft wird von Eichen
        dominiert und von der einstigen Vegetation ist nicht mehr viel übrig. Der Boden ist größtenteils beschädigt und
        enthält nur sehr wenig organisches Material. Das folgende Bild stammt aus dem Sommer 2022:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-08_21-17-36.jpg">
        <img src="../../assets/images/2022-06-08_21-17-36.jpg">
      </picture>

      <p>
        Ein Ökosystem ist komplex und es gibt keinen klaren Weg, um etwas zu erreichen. Die Natur ist additiv und für
        alles gibt es mehrere Wege und mehrere Akteure. Die Natur ist widerstandsfähig, denn man kann ein Element
        entfernen und das Ökosystem funktioniert weiter. Entfernt man jedoch zu viel, führt dies wahrscheinlich zu
        seinem Niedergang.
      </p>
      <p>
        Für uns bedeutet das, dass wir gleichzeitig an mehreren Themen arbeiten und Entscheidungen auf der Grundlage von
        Wissen treffen aber zu einem großen Teil auf der Grundlage von Beobachtungen. Was anderswo funktioniert, ist für
        uns eine Inspiration, aber wir denken, wir sollten nicht kopieren, sondern unseren eigenen Weg finden, ein
        Prinzip anzuwenden, das in einer ähnlichen Situation wie der unseren funktioniert hat.
      </p>

      <h2 class="underdashed text-xl">Landwirtschaft</h2>
      <p>
        Da der Boden ausgelaugt ist und <router-link :to="{ name: 'desertification' }">
          Wüstenbildung</router-link> deutlich zu erkennen ist, gibt es keine Möglichkeit, zu diesem Zeitpunkt etwas zu
        pflanzen oder zu ernten.
        Das wurde in der Vergangenheit bereits getan und hat zu der jetzigen Situation geführt. Wir nutzen lieber die
        vorhandenen Ressourcen, einschließlich das Wasser, um den Boden und den Wald wiederherzustellen, so dass wir
        später in der Lage sind, Agroforstwirtschaft zu betreiben, um ein Produkt zu erhalten.
      </p>

      <h2 class="underdashed text-xl">Wirkung tierischer Aktivitäten</h2>
      <p>
        Wir halten eine größere Anzahl von Tieren, da sie eine wichtige Funktion bei unseren
        Wiederherstellungsbemühungen erfüllen. Zum Beispiel, ihre Ausscheidungen fügen dem Boden nicht nur Nährstoffe
        hinzu, sondern ihre Hufe öffnen auch die Kruste, damit Wasser eindringen kann. Und auch das Wühlen der
        Schweine hilft dem Boden. Wir verbringen viel Zeit damit, die Auswirkungen der Aktivitäten der Tiere richtig zu
        koordinieren.
      </p>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "ConceptDE",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
